/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  Tracker.js
Author:    John D. Kohl
Version:   1.00
Description:
Show tracking information for the given consignment.

History:
2.01  21-11-24 JDK   Implemented tracking URL config.
2.00  12-11-24 JDK   Refactored to display multiple tracking "phases", handle updated event codes.
1.00  21-10-24 JDK   Feature complete: layout matched to standalone tracking web page, added show/hide function.
0.03  18-10-24 JDK   Display tracking event date & time if available.
0.02  15-10-24 JDK   Use connoteapi URL for tracking.
0.01	10-10-24 JDK   Created.
----------------------------------- */

import { useEffect, useState } from "react";
import "./Tracker.css";
import { trackingURL } from "../Configuration/Config";
import { PropagateLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBicycle, faBox, faCalendarDays, faClipboardList, faFileSignature, faTruckFast, faTruckRampBox, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import Bugsnag from "@bugsnag/js";
import { faBoxCheck, faLocationCheck, faPersonDolly } from "@fortawesome/pro-solid-svg-icons";

// Events

const events = {
  pickup: [
    { code: 0, text: "Booked", icon: faCalendarDays, titleText: "has been scheduled for pickup" },
    // No tracking for code 1
    { code: 2, text: "Assigned", icon: faClipboardList, titleText: "has been assigned to a driver for pickup" },
    { code: 3, text: "On Board", icon: faTruckFast, titleText: "has been picked up and is on board"},
    { code: 4, text: "In Depot", icon: faTruckRampBox, titleText: "has arrived at the depot" },
    { code: 5, text: "Received", icon: faBoxCheck, titleText: "pickup has been completed" },
  ],
  transit: [
    { code: 10, text: "Booked", icon: faCalendarDays, titleText: "has been scheduled for transport"},
    { code: 20, text: "Loaded", icon: faTruckRampBox, titleText: "has been loaded for departure"},
    { code: 35, text: "Departed", icon: faTruckFast, titleText: "is departing the sending depot" },
    { code: 41, text: "Arrived", icon: faLocationCheck, titleText: "is arriving at the receiving depot" },
    { code: 44, text: "In Depot", icon: faWarehouse, titleText: "has been received in depot" },
    { code: 45, text: "Unloaded", icon: faPersonDolly, titleText: "has been unloaded for delivery" },
  ],
  delivery: [
    { code: 52, text: "Assigned", icon: faClipboardList, titleText: "has been assigned to a driver for delivery" },
    { code: 53, text: "On Board", icon: faTruckFast, titleText: "has been loaded for delivery" },
    { code: 55, text: "Delivered", icon: faFileSignature, titleText: "has been delivered" }
  ]
}

// Fields required for each step of the stepper displaying tracking history.
class StepperEvent {
  constructor ({ text, icon, complete, current, date, time, titleText }) {
    this.text = text;
    this.icon = icon ? icon : null;
    this.complete = complete;
    this.current = current;
    this.date = date;
    this.time = time;
    this.titleText = titleText;
  }
}

// Must match the return structure of the connoteapi /track GET method.
class TrackingInfo {
  constructor(
    { consignmentNumber, sender, receiver, from, to, events } = { 
      consignmentNumber: null,
      sender: '',
      receiver: '',
      from: '',
      to: '',
      events: {
        pickup: [],
        transit: [],
        delivery: [],
        display: []
      },
    }) {
    this.consignmentNumber = consignmentNumber;
    this.sender = sender;
    this.receiver = receiver;
    this.from = from;
    this.to = to;
    this.events = trackingEvents(events, findCurrentEvent(events));
  }
}

function findCurrentEvent(events) {
  let eventCode = null;
  Object.values(events).forEach((eventList) => {
    eventList && eventList.forEach(event => {
      eventCode = Math.max(eventCode, event.code)
    })
  });
  return eventCode;
}

function processEvents(eventSubset = [], eventsFromAPI = [], currentEventCode = null) {
  // console.log("TrackingEvents function, processEvents log:",eventSubset, eventsFromAPI);
  return eventSubset.length ? Array.from(eventSubset, (eventPart1, index) => {
    let eventPart2 = eventsFromAPI ? eventsFromAPI.find((apiEvent) => apiEvent.code === eventPart1.code || (apiEvent.code > eventPart1.code && apiEvent.code < eventSubset[index+1].code)) : null;
    return new StepperEvent({ ...eventPart1, ...eventPart2, ...eventInfo(eventPart2, eventPart1.code, currentEventCode) });
  }) : [];
}

function eventInfo(event, code, currentEventCode = null) {
  if (event) {
    let eventTimestamp = new Date(event.timestamp);
    return { current: event.code === currentEventCode, complete: true, date: eventTimestamp.toLocaleDateString(), time: eventTimestamp.toLocaleTimeString() }
  }
  return { current: false, complete: (currentEventCode && code) ? code < currentEventCode : false, date: '', time: '' }
}

function trackingEvents ({ pickup, transit, delivery } = { pickup: [], transit: [], delivery: [] }, currentEventCode = null) {
  return {
    pickup: processEvents(events.pickup, pickup, currentEventCode),
    transit: processEvents(events.transit, transit, currentEventCode),
    delivery: processEvents(events.delivery, delivery, currentEventCode),
    display: [
      { tabName: "pickup", disable: !Boolean(pickup && pickup.length) },
      { tabName: "transit", disable: !Boolean(transit && transit.length) },
      { tabName: "delivery", disable: !Boolean(delivery && delivery.length) },
    ]
  }
}

function Stepper({events = []}) {
  // console.log(events);
  const stepperItems = Array.from(events, (event) => {
    return (
      <div className={`stepper-item ${event.complete ? "completed" : ""} ${event.current ? "current" : ""}`}>
        <div className="step-count">
          {event.current && <FontAwesomeIcon icon={event.icon} size="2x"/>}
          {/* <FontAwesomeIcon icon={event.icon} size="2x"/> */}
        </div>
        <p>{event.text}&zwnj;</p>
        <span>{event.date}&zwnj;</span>
        <span>{event.time}&zwnj;</span>
      </div>
    )
  }); // This defines the HTML for the actual stepper
  return stepperItems;
}

// Generate an array of StepperSteps from the events returned by the tracking API for use in generating the stepper.
// Customisation: the title text and icons could be defined via the configuration mechanism.
function TrackerTabs ({ events, showStepper } = {events: trackingEvents(), showStepper: false }) {
  // console.log({events});
  const [ display, setDisplay ] = useState("");
  const handleTabClick = (key, e) => {
    e.preventDefault();
    setDisplay(key);
  };
  const tabs = Array.from(events.display, ({tabName, disable}) => {
    return (<button className={`${display === tabName && "active"}`} disabled={disable} onClick={(e) => {handleTabClick(tabName, e)}}>{tabName.charAt(0).toUpperCase() + tabName.slice(1)}</button>);
  });

  useEffect(() => {
    let tab = events.display.findLast((tab) => !tab.disable);
    let currentTab = events.display.find((tab) => tab.tabName === display);

    let canSetDefault = !display && tab;
    let shouldResetTab = tab && currentTab && currentTab.disable;

    if ( canSetDefault || shouldResetTab ) {
      setDisplay(tab.tabName);
    }
  }, [events.display, display])

  return (
    <>
    { showStepper
    ? <>
      <div className={`stepper-container ${display === "pickup" ? "active" : ""}`}>
        {<Stepper events={events.pickup} />}
      </div>
      <div className={`stepper-container ${display === "transit" ? "active" : ""}`}>
        {<Stepper events={events.transit} />}
      </div>
      <div className={`stepper-container ${display === "delivery" ? "active" : ""}`}>
        {<Stepper events={events.delivery} />}
      </div>
    </>
    : <div className="stepper-container loading">
        <PropagateLoader className="react-spinner" loading={true} aria-label="Loading label" data-testid="loader" />
      </div>}
    <div className="stepper-tabs">
      {tabs}
    </div>
    </>
  );
}

// React functional component to display the address information of the tracked consignment.
function TrackerHeader({trackingInfo} = {trackingInfo: new TrackingInfo() }) {
  const unitSeparator = '\u001f'; // ASCII Unit separator. This is the delimiter for the address lines, at least until a more suitable method is found.
  let titleText = '';

  Object.values(trackingInfo.events).forEach((eventArray) => {
    if (Array.isArray(eventArray)) {
      let currentEvent = eventArray.find((event) => event.current);
      titleText = currentEvent ? currentEvent.titleText : titleText;
    }
  })
  // console.log(trackingInfo);
  let emptyAddress = (<>&zwnj;<br/>&zwnj;<br/></>); // &zwnj; = Zero width non-joining character. This sequence will display as two blank lines, ensuring relative consistency in size for this component when displaying blank addresses.
  return (
    <>
      <h3 className="tracking-title">{ trackingInfo.consignmentNumber
        ? (titleText ? `Your consignment #${trackingInfo.consignmentNumber} ${titleText}.` : `No tracking information available for consignment #${trackingInfo.consignmentNumber}.`)
        : 'Loading tracking information...'}
      </h3>
      <div className="consignment-info">
        <div>
          <h3>From:</h3>
          <p>{trackingInfo.sender}&zwnj;</p>
          {trackingInfo.from ? Array.from(trackingInfo.from.split(unitSeparator), (line) => <p>{line}&zwnj;</p>) : emptyAddress}
        </div>
        <div>
          <h3>To:</h3>
          <p>{trackingInfo.receiver}</p>
          {trackingInfo.to ? Array.from(trackingInfo.to.split(unitSeparator), (line) => <p>{line}&zwnj;</p>) : emptyAddress}
        </div>
      </div>
    </>
  )
}

// Complete React functional component
function Tracker({trackingUUID, consignmentNumber, show=true}) {
  const [ trackingInfo, setTrackingInfo ] = useState(new TrackingInfo());
  const [ display, setDisplay ] = useState(false);

  useEffect(() => {
    // console.log('consignment number changed: ', consignmentNumber);
    setTrackingInfo(new TrackingInfo());
  }, [consignmentNumber])

  useEffect(() => {
    // The RESTful request does not use the portal API, but instead the general ConNote API. This also lets us define a useEffect without overriding dependency array warnings.
    const fetchTrackingData = async (id) => {
      let response = await fetch(`${trackingURL}/track?psTrackingID=${id}`);
      try {
        console.log('requesting tracking info...');
        response = await response.json();
        console.log('response received, processing tracking info');
        setTrackingInfo(new TrackingInfo(response));
        setDisplay(true);
      } catch (error) {
        Bugsnag.notify(error); // In development, the response would occasionally fail to parse. The catch block will prevent the application from crashing (graceful failure), and Bugsnag will report the error as a warning (handled exception).
      }
    };
    trackingUUID && fetchTrackingData(trackingUUID); // If we have a tracking UUID, call the function defined above.
    return () => {
      setDisplay(false);
    };
  }, [trackingUUID]) // Only one value to track! No warnings or errors!

  return (
    <div className="tracking-container" hidden={show ? undefined : true}>
      <TrackerHeader trackingInfo={trackingInfo}></TrackerHeader>
      <TrackerTabs events={trackingInfo.events} showStepper={display}></TrackerTabs>
    </div>
  )
}

export default Tracker