/* -----------------------------------
Copyright: Logical Developments 2024.
Project:   ConNote Portal
Filename:  Dashboard.js
Author:    John D. Kohl
Version:   1.00
Description:
Show consignment numbers and display selected consignments.

History:
1.01  12-11-24 JDK   (LD0013075) Improve performance by removing unnecessary effect dependency; added lint disabling to effect with "missing" dependency.
1.00  21-10-24 JDK   (LD0013075, LD0009836) Show consignment tracking info; change version to 1.00 as portal is released.
0.13  19-11-24 JDK   (LD0013142) Bug fix: updated handling of return values to refer to most recent API fields.
0.12  16-10-24 JRB   (ld0013078) Added handling for labels. This is a new set of buttons that can generate labels. Improved existing handling. If a file doesn't exist, it now displays as such to prevent the user attempting to download/print something non-existant.
0.11  25-11-22 DBL   (ld0011792) Go home and not back
0.10  23-08-22 DBL   Change setModel to use authModal
0.09  08-07-22 JRB   Added a catch for if the retrieved consignment didn't exist for some reason
0.08  07-06-22 ADM   Added Magnifying Glass to search bar
0.07  09-05-22 DBL   Tweaked the scroll function
0.06  05-05-22 ADM   Added the function that scrolls the button for a consignment into view when coming from exisiting quotes.
0.05  04-05-22 DBL   Moved to Documents and added check for loaction and permissions
0.04  14-04-22 JDK   Final Draft
0.03  13-04-22 JDK   Completed Print and Save functionality and moved from Consignment.js
0.02  10-03-22 JDK   Removed useEffect monitoring to prevent recursive data retrieval
0.01  09-03-22 ADM   Reordered the table cells and buttons in the listCons. Added class "table-narrow"
0.00	01-03-22 JDK   Created.
----------------------------------- */

import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate as useRouterNavigate } from 'react-router-dom';
import useNavigate from '../Navigation/useNavigate';
import useAuthenticate from '../Session/useAuthenticate';
import CardForm from '../Common/CardForm';
import useRest from '../Session/useRest';
import './Documents.css';
import JsFileDownloader from 'js-file-downloader';
import print from 'print-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Tracker from './Tracker';

function Consignments() {
  const { loggedIn, customer, authModal } = useAuthenticate();
  const navigate = useRouterNavigate();
  const location = useLocation();
  const button = useRef();
  const { changeType } = useNavigate();
  const [ fetch, data, error, loading ] = useRest('GET', 'documents/list');
  const [ retrieveCon, docURL ] = useRest('PDF', 'documents/pdf'); // no error handling, as useREST only returns the URL for the PDF.
  const [ retrieveLBL, lblURL ] = useRest('PDF', 'documents/pdf'); // no error handling, as useREST only returns the URL for the PDF.
  const [ generate, status, , docLoading ] = useRest('GET', 'documents/generate');
  const [ generateLbl, Lblstatus, , lblLoading ] = useRest('GET', 'documents/generate');
  const [ conNum, setNum ] = useState(null);
  const [ requestLabel, setRequestLabel ] = useState(false);
  const [ genError, setGenError ] = useState(null);
  const [ searchFilter, setSearchFilter ] = useState(null);
  const [ showTracker, setShowTracker ] = useState(false);
  const [ currentLblUrl, setCurrentLblUrl] = useState(null);

  const handlePDF = useCallback((conNum) => {
    // console.log(conNum, status, docError, docLoading);
    // console.log(status && status.trackingID);
    if (conNum && !docLoading) { // always need a conNum, and don't send a request if one already in progress
      if (status && status.success) {
        if (status.recordNumber === conNum) { // Is the expected result the consignment number?
          retrieveCon({docRN: conNum, docType: 'CON', print: false}); // get the PDF.
          setRequestLabel(true);
        }
        else {
          generate({docRN: conNum, docType: 'CON', print: false});
          setRequestLabel(false);
        }
      }
      else if (status && !status.success) {
        if (status.message !== `${conNum} not found`)
          generate({docRN: conNum, docType: 'CON', print: false});
        else {
          setGenError(`${conNum} not found`); 
          setRequestLabel(false);
          setCurrentLblUrl(''); // If there is no document then there shouldn't be a label either.
        }
      }
      else if (!status)
        generate({docRN: conNum, docType: 'CON', print: false});
    }
  }, [status, docLoading, retrieveCon, generate])

  const handleLabelPDF = useCallback((conNum) => {
    // console.log(conNum, lblLoading, Lblstatus, currentLblUrl, lblURL)
    if (conNum && !lblLoading) { // always need a conNum, and don't send a request if one already in progress
      if (Lblstatus && Lblstatus.success) {
        if (Lblstatus.recordNumber === conNum) {
          retrieveLBL({docRN: conNum, docType: 'LBL', print: false}); // get the PDF.
          setRequestLabel(false);           
        }
        else 
          generateLbl({docRN: conNum, docType: 'LBL', print: false});
      }
      else if (Lblstatus && !Lblstatus.success) { // The Label does not exist
        setRequestLabel(false); 
        if (Lblstatus.message === `${conNum} not found`) // If the connum changes this message will change and so we can detect if the current has or hasn't failed!
          setCurrentLblUrl(''); // Clear the URL since we don't have one if the consignment isn't found.
        else // try again
          generateLbl({docRN: conNum, docType: 'LBL', print: false});
      }
      else if (!Lblstatus)
        generateLbl({docRN: conNum, docType: 'LBL', print: false});
    }
  }, [Lblstatus, lblLoading, retrieveLBL, generateLbl])

  // on first load, set the navigation type and initial state
  useEffect(() => {
    changeType('default', 'Consignments');
    if (loggedIn) {
      if (customer.permissions.Consignments.view === true) {
        fetch({docType: 'CON'});
        if (location.state !== null && location.state.hasOwnProperty('consignmentNumber'))
          setNum(location.state.consignmentNumber) // we were passed a consignment
      }
      else
        permissionDenied('You do not have access to view consignments.');
    }
  }, [loggedIn]); // eslint-disable-line

  useEffect(() => {
    if (error && error.toLowerCase() === 'permission denied')
      permissionDenied('You do not have access to view consignments.');
  }, [error]) // eslint-disable-line

  const permissionDenied = (description) => {
    authModal({
      title:'Permission Denied',
      description: description,
      yes: {name: 'Go Back', call: () => navigate('/')} // go home (ld0011792)
    });
  };

  // when the data returns select the first consignment
  useEffect(() => {
    if (data && !conNum) setNum(data.documents[0].docRN);
  }, [data, conNum])

  useEffect(() => { // load pdf when search narrows list down to one
    if (data && searchFilter) {
      let results = data.documents.filter(doc => doc.docRN.includes(searchFilter));
      if (results.length === 1) setNum(results[0].docRN);
    }
  }, [data, searchFilter])

  // when the data arrives, check if we automatically loaded a consignment
  useEffect(() => {
    if (location.state !== null && location.state.hasOwnProperty('consignmentNumber') && data && button.current && docURL) { 
      if (button.current !== undefined) { // if we do have a reference to the button for the consignment number we were passed.
        button.current.scrollIntoView();
      } else  {
        authModal({
          title: 'Consignments',
          description: `Could not locate scanned original for ${location.state.consignmentNumber}`,
          yes: {name: 'OK', call: () => {}}
        });
      }
    }
  }, [button, docURL, data, location, authModal]);

  // fetch the pdf URL when the customer clicks on a consignment
  useEffect(() => {
    // if (conNum !== null) retrieve({docRN: conNum, docType: 'CON', canPrint: false});
    conNum && handlePDF(conNum);
  }, [conNum, handlePDF]);

  useEffect(() => { // The URL for the label has changed or we have returned from loading the label.
    // console.log(lblURL, currentLblUrl)
    if (!lblLoading) // If we were loading at some point then we must have selected a new entry. Therefore to handle the case of returning to the same lblURL we can check lblLoading to see if we might need to rerun this.
      if (lblURL !== currentLblUrl) { 
        setCurrentLblUrl(lblURL);
      } 
  }, [lblURL, lblLoading]) // eslint-disable-line

  useEffect(() => { // After successfully fetching a consignment, fetch the label and toggle the requestLabel flag.
    if (requestLabel) {
      handleLabelPDF(conNum);
    }
  }, [conNum, requestLabel, setRequestLabel, handleLabelPDF])

  // open the browser print dialogue using the print-js module, if enabled
  const handlePrint = (enabled) => {
    if (enabled) {
      // console.log(docURL);
      if (docURL) {
        print(docURL);
      }
    }
  }
  
  // open the browser save dialogue using the js-file-downloader module
  // here we can override the automatic naming with the actual document type and number
  const handleSave = (enabled) => {
    if (enabled) {
      if (docURL) {
        const download = new JsFileDownloader({
          url: docURL,
          autoStart: false,
          forceDesktopMode: true,
          filename: `Consignment_${conNum}.pdf`
        })
        download.start()
      }
    }
  }

  const saveLabel = () => {
    if (conNum !== null) {
      if (currentLblUrl) {
        const download = new JsFileDownloader({
          url: currentLblUrl,
          autoStart: false,
          forceDesktopMode: true,
          filename: `Consignment_${conNum}_Label.pdf`
        })
        download.start()
      }
    }
  }

  const printLabel = () => {
    if (conNum !== null) {
      // console.log('print label')
      if (currentLblUrl)
        print(currentLblUrl);
      // else
        // console.log('Label URL not yet ready')
    }
  }

  // custom and next appear next to each other (left to right); rename to be print and save
  // only enable when a POD is selected (podNum !== null)
  let controls = {
    custom: { call: handlePrint, name:'Print', enable: (conNum !== null && genError === null)},
    next: { call: handleSave, name:'Save', enable: (conNum !== null && genError === null) }
  };

  return (
    <CardForm title='Consignments' className="margin-block-start" controls={controls} error={error || genError}>
      <div className='stack'>
        <Tracker consignmentNumber={conNum} trackingUUID={status && status.trackingID} show={showTracker}></Tracker>
      </div>
      <label
        htmlFor='search-bar'
        className="cluster cluster-no-wrap no-measure search"
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
        <input type='text' id='search-bar'
          placeholder='Search…'
          onChange={(e) => setSearchFilter(e.target.value)}
          className="no-measure"
          style={{border: "none"}}
        />
        <button className='btn btn-inline' id='show-tracker'
          onClick={(e) => {
            e.preventDefault();
            setShowTracker(!showTracker);
          }}>{`${showTracker ? "Hide" : "Show"} Tracking Info`}
        </button>
      </label>
      <div className = 'flex_container' style={{marginBlockStart: '0rem'}}>
        <div className='stack'>
          <p className='h3 table-heading' style={{textAlign: "center"}}>Consignment</p>
          <div className='stack table'>
            { loading ? 'Loading…'
              : (data !== null && data.documents
                .filter(doc => searchFilter ? doc.docRN.includes(searchFilter) : true)
                .map((doc) =>
                  <button type='button'
                    key={`CON${doc.id}`}
                    ref={btn => {
                      if (location.state && doc.docRN === location.state.consignmentNumber) // if the button being generated is for a consignment that was passed, get a reference to it.
                        button.current = btn
                    }}
                    onClick={() => {
                      setNum(doc.docRN);
                      setGenError(null);
                    }}
                    className={doc.docRN === conNum ? 'checked' : 'not clicked'}
                    autoFocus={(location.state && doc.docRN === location.state.consignmentNumber) ? true : undefined}
                  >
                    {doc.docRN}
                  </button>
                )
              )
            }
          </div>
        </div>
        <object data={!(genError || docLoading) ? docURL : undefined} type="application/pdf" width="100%">
          <p className="h3" style={{textAlign:"center", marginBlockStart: "0.5rem"}}>            
            {docURL
            ? (genError
              ? `Error requesting document: #${genError}`
              : (docLoading ? `Loading document #${conNum}...` : `Error loading document #${conNum}`))
            : 'Please select a Consignment' }
          </p>
        </object>
      </div>
      <div className='cluster cluster-no-wrap margin-inline-start-auto'>
        <button className= { (currentLblUrl === null || currentLblUrl === '')? 'btn disabled' : 'btn'}
        type='button'
        onClick={printLabel} 
        disabled={currentLblUrl === null || currentLblUrl === ''}
        >
          {(currentLblUrl === null ? 'loading label' : currentLblUrl === '') ? 'No Label' : 'Print Label'}
        </button>
        <button className= { (currentLblUrl === null || currentLblUrl === '') ? 'btn disabled' : 'btn'}
        type='button'
        onClick={saveLabel} 
        disabled={(currentLblUrl === null || currentLblUrl === '')}
        >
          {(currentLblUrl === null ? 'loading label' : currentLblUrl === '') ? 'No Label' : 'Save Label'}
        </button>
      </div>
    </CardForm>
  );

}
 
export default Consignments;